import { SelectionChangedEvent } from "ag-grid-community";
import { INITIAL_STANDARD_PROPOSAL_TARIFF_ITEM_TIER_COL_DEF, transferRowData } from "presentation/constant/StandardProposal/StandardProposalTariffItemTierColumnDefinition";
import { useStandardProposalDetailVM } from "presentation/hook/StandardProposal/useStandardProposalDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { StandardProposalDetailModel } from "presentation/model/StandardProposal/StandardProposalDetailModel";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";
import AddStdTariffItemIsTablePanel from "./AddStdTariffItemIsTablePanel";
import AddTariffItemHeaderBar from "./AddTariffItemHeaderBar";
import AddTariffItemIsEditPanel from "./AddTariffItemIsEditPanel";


const AddTariffItemTierTablePanel = ({standardProposalDtlState}:{standardProposalDtlState:StandardProposalDetailModel}) => {
    // const [standardProposalDtlState] = useStandardProposalDetailTracked();
    const standardProposalDtlVM = useStandardProposalDetailVM();
    const {  isAddIs,isAddTier,isEditIs,isEditTier,currentSelectItem,currentTariffItemTier,tariffItemTierSelectedRows } = standardProposalDtlState;
    let gridRef: any = useRef();
    const messageBarVM = useMessageBarVM();

    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);

    useEffect(() => {
        if (onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_STANDARD_PROPOSAL_TARIFF_ITEM_TIER_COL_DEF.slice());
        
        // if (!standardProposalState.tariffItemSelectedRows ||
        //     standardProposalState.tariffItemSelectedRows.length <= 0) {        
            gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        // }
    })


    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        standardProposalDtlVM.updateSelectedTariffItemTierRows(selectedRows);
    }, [standardProposalDtlVM])


    const handleAdd = useCallback(() => {
        standardProposalDtlVM.onAddTier();
    }, [standardProposalDtlVM]);

    const handClose = useCallback(() => {
        standardProposalDtlVM.onTariffItemTierCloseClick();
    }, [standardProposalDtlVM]);


    const handEdit = useCallback(() => {
        if(currentTariffItemTier?.cycle == null){
            messageBarVM.showError("Please select a Tier record.");
            return;
        }
        standardProposalDtlVM.onTariffItemTierEidtClick();
    }, [currentTariffItemTier?.cycle, messageBarVM, standardProposalDtlVM]);

    const handDelete = useCallback(() => {
        if(tariffItemTierSelectedRows.length === 0){
            messageBarVM.showError("Please select a record.");
            return;
        }
        standardProposalDtlVM.onTariffItemTierDeleteClick(tariffItemTierSelectedRows);
    }, [messageBarVM, standardProposalDtlVM, tariffItemTierSelectedRows]);
    
    const standardProposalTariffItemTierTable = 
        <HPHTable
            id='standard-proposal-detail-table'
            isNewColumnSetting={true}
            columns={INITIAL_STANDARD_PROPOSAL_TARIFF_ITEM_TIER_COL_DEF.slice()}
            data={transferRowData(currentSelectItem?.tierList ?? [])}
            headerActionButtons={[
                {
                    id: 'onEditButton',
                    icon: 'Icon-pen',
                    title: 'Edit'
                },
                {
                    id: 'onDeleteButton',
                    icon: 'Icon-trash',
                    title: 'Delete'
                },
                {
                    id: 'onCloseButton',
                    icon: 'Icon-cross',
                    title: 'Close'
                },
            ]}
            onEditButton={handEdit}
            onDeleteButton={handDelete}
            onCloseButton={handClose}
            showPaginator={false}
            editable={false}
            showAddIcon={true}
            onAddClick={handleAdd}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            showUploadIcon={false}
            rowSelection={"single"}
            isRowHighligted={true}
            onSelectionChanged={handleSelectionChange}
            gridHeight="customHeight"
            customHeight="calc(100vh - 780px)"
            ref={gridRef}
        />



    return <><div className="main-comp-wrapper">
        <div style={{width: '100%', height: '100%', maxHeight: '85%'}}>
            <AddTariffItemHeaderBar/>
            <TableWrapper>
            {standardProposalTariffItemTierTable}
            </TableWrapper>
            <AddStdTariffItemIsTablePanel/>
            {<div className={`child-div${(isAddIs||isAddTier||isEditIs||isEditTier)? " child-div-std-tariff-item-edit-expanded " : ""}`}>
                <AddTariffItemIsEditPanel standardProposalDtlState={standardProposalDtlState}/>
            </div>}
        </div>
    </div></>;
}

export default memo(AddTariffItemTierTablePanel);
